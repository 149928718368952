import React from "react";
import "../css/privacy.css";

function PartnerPrivacy() {
  return (
    <div className="privacy-policy-container-p">
      <div className="content-wrapper-p">
        <h1 className="section-title-p">Privacy Policy</h1>
        <p className="effective-date-p">Effective Date: 17/12/2024</p>

        <p className="text-p">
          TrimlyXpert Partner ("we", "our", "us") operates the TrimlyXpert Partner mobile application and website (
          <a href="https://trimlyxpert.com/" target="_blank" rel="noopener noreferrer">
            www.trimlyxpert.com
          </a>
          ). This Privacy Policy explains how we collect, use, and protect your personal information when you use our services.
        </p>

        <h2 className="title-p">1. Information We Collect</h2>
        <p className="text-p">We collect the following types of information:</p>

        <h3 className="subtitle-p">Personal Information:</h3>
        <ul className="ul-list-p">
          <li className="list-p">Full Name</li>
          <li className="list-p">Email Address</li>
          <li className="list-p">Phone Number</li>
          <li className="list-p">Business Address</li>
          <li className="list-p">Account Credentials (Email, Password)</li>
        </ul>

        <h3 className="subtitle-p">Usage Data:</h3>
        <ul className="ul-list-p">
          <li className="list-p">Appointment Bookings and History</li>
          <li className="list-p">Earnings and Transactions</li>
          <li className="list-p">Service Offerings and Availability</li>
        </ul>

        <h2 className="title-p">2. How We Use Your Information</h2>
        <ul className="ul-list-p">
          <li className="list-p">Facilitate service bookings with customers</li>
          <li className="list-p">Process payments and payouts</li>
          <li className="list-p">Improve and optimize our platform</li>
          <li className="list-p">Send appointment notifications and updates</li>
          <li className="list-p">Comply with legal and regulatory requirements</li>
        </ul>

        <h2 className="title-p">3. Sharing Your Information</h2>
        <p className="text-p">We do not sell or rent your personal information. However, we may share it with:</p>
        <ul className="ul-list-p">
          <li className="list-p"><strong>Customers:</strong> To facilitate service bookings.</li>
          <li className="list-p"><strong>Service Providers:</strong> For payment processing and business support.</li>
          <li className="list-p"><strong>Legal Compliance:</strong> If required by law or to protect user rights.</li>
        </ul>

        <h2 className="title-p">4. Data Security</h2>
        <p className="text-p">We implement reasonable security measures to protect your data from unauthorized access or misuse.</p>

        <h2 className="title-p">5. Your Rights</h2>
        <ul className="ul-list-p">
          <li className="list-p">Access, update, or correct your personal information</li>
          <li className="list-p">Request data deletion, subject to legal conditions</li>
          <li className="list-p">Withdraw consent for data processing where applicable</li>
        </ul>
        <p className="text-p">
          To exercise your rights, contact us at <a className="link-p" href="mailto:trimlyxpert@gmail.com">trimlyxpert@gmail.com</a>.
        </p>

        <h2 className="title-p">6. Cookies and Tracking Technologies</h2>
        <p className="text-p">We use cookies and similar technologies to enhance user experience. You can manage your cookie preferences via browser settings.</p>

        <h2 className="title-p">7. Data Retention</h2>
        <p className="text-p">We retain personal information as long as necessary to fulfill our obligations unless a longer period is required by law.</p>

        <h2 className="title-p">8. Data Deletion</h2>
        <p className="text-p">
          If you wish to delete your personal data, please send a request via email. Upon verification, we will process your request in accordance with applicable laws.
        </p>
        <p className="text-p">
          Contact: <a className="link-p" href="mailto:trimlyxpert@gmail.com">trimlyxpert@gmail.com</a>
        </p>

        <h2 className="title-p">9. Changes to this Privacy Policy</h2>
        <p className="text-p">
          We may update our Privacy Policy from time to time. We will notify you
          of any material changes by posting the updated policy on this page
          with a revised "Updated Date: 11/02/2025". Please review this Privacy
          Policy periodically to stay informed about how we are protecting your
          information.
        </p>

        <h2 className="title-p">10. Contact Us</h2>
        <p className="text-p">For any questions or concerns, contact us at:</p>
        <address className="address-p">
          Email:{" "}
          <a className="link-p" href="mailto:trimlyxpert@gmail.com">trimlyxpert@gmail.com</a>
          <br />
          Website: <a className="link-p" href="https://trimlyxpert.com/">www.trimlyxpert.com</a>
        </address>
      </div>
    </div>
  );
}

export default PartnerPrivacy;
