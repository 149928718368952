import React from "react";
import { Routes, Route } from "react-router-dom";
import Header from "../Components/Header";
import HeroSection from "../Pages/HeroSection";
import Features from "../Pages/Features";
import Pricing from "../Pages/Pricing";
import Testimonials from "../Pages/Testimonials";
import Footer from "../Components/Footer";
import Privacy from "../Pages/Privacy";
import CaseStudies from "../Pages/CaseStudies";
import Dashboard from "../Pages/Dashboard";
import Services from "../Pages/Services";
import NewService from "../Pages/NewService";
import Profile from "../Pages/Profile";
import History from "../Pages/History";
import ServiceAddress from "../Pages/ServiceAddress";
import UpdateProfile from "../Components/Profile/UpdateProfile";
import AddServiceAddress from "../Components/Address/AddServiceAddress";
import Bookings from "../Pages/Bookings";
import ProtectedRoute from "../Components/ProtectedRoute";
import PartnerPrivacy from "../Pages/PartnerPrivacy";

const AppRoutes = ({ isAuthenticated }) => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <>
            <Header /> <HeroSection />
            <Footer />
          </>
        }
      />
      <Route
        path="/features"
        element={
          <>
            <Header /> <Features />
            <Footer />
          </>
        }
      />
      <Route
        path="/pricing"
        element={
          <>
            <Header /> <Pricing />
            <Footer />
          </>
        }
      />
      <Route
        path="/testimonials"
        element={
          <>
            <Header /> <Testimonials />
            <Footer />
          </>
        }
      />
      <Route
        path="/casestudies"
        element={
          <>
            <Header /> <CaseStudies />
            <Footer />
          </>
        }
      />
      <Route
        path="/privacy"
        element={
          <>
            <Privacy />
          </>
        }
      />
      <Route
        path="/partner-privacy"
        element={
          <>
            <PartnerPrivacy />
          </>
        }
      />
      <Route
        path="/dashboard"
        element={
          <ProtectedRoute isAuthenticated={isAuthenticated}>
            <Dashboard />
          </ProtectedRoute>
        }
      />
      <Route
        path="/dashboard/services"
        element={
          <ProtectedRoute isAuthenticated={isAuthenticated}>
            <Services />
          </ProtectedRoute>
        }
      />
      <Route
        path="/dashboard/newService"
        element={
          <ProtectedRoute isAuthenticated={isAuthenticated}>
            <NewService />
          </ProtectedRoute>
        }
      />
      <Route
        path="/dashboard/profile"
        element={
          <ProtectedRoute isAuthenticated={isAuthenticated}>
            <Profile />
          </ProtectedRoute>
        }
      />
      <Route
        path="/dashboard/profile/updateProfile"
        element={
          <ProtectedRoute isAuthenticated={isAuthenticated}>
            <UpdateProfile />
          </ProtectedRoute>
        }
      />
      <Route
        path="/dashboard/history"
        element={
          <ProtectedRoute isAuthenticated={isAuthenticated}>
            <History />
          </ProtectedRoute>
        }
      />
      <Route
        path="/dashboard/addresses"
        element={
          <ProtectedRoute isAuthenticated={isAuthenticated}>
            <ServiceAddress />
          </ProtectedRoute>
        }
      />
      <Route
        path="/dashboard/profile/addServiceAddress"
        element={
          <ProtectedRoute isAuthenticated={isAuthenticated}>
            <AddServiceAddress />
          </ProtectedRoute>
        }
      />
      <Route
        path="/dashboard/bookings"
        element={
          <ProtectedRoute isAuthenticated={isAuthenticated}>
            <Bookings />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
};

export default AppRoutes;
