import React, { useState, useEffect } from "react";
import Sidebar from "../../Components/Sidebar";
import { APIEndtPoint } from "../../API/Apis";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader";
import PositionedSnackbar from "../PositionedSnackbar";

function UpdateProfile() {
  const navigate = useNavigate();
  const vendor_id = localStorage.getItem("token");
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    // email: '',
    phone_number: "",
    address: "",
    opening_time: "",
    closing_time: "",
  });

  useEffect(() => {
    const fetchVendorDetails = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${APIEndtPoint}/vendors/${vendor_id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (response.ok) {
          const data = await response.json();
          const vendor = data.vendor || {};
          setFormData({
            name: vendor.name || "",
            // email: vendor.email || '',
            phone_number: vendor.phone_number || "",
            address: vendor.address || "",
            opening_time: vendor.opening_time || "",
            closing_time: vendor.closing_time || "",
          });
        } else {
          throw new Error(`HTTP error: Status ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching vendor details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchVendorDetails();
  }, [vendor_id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      vendor: Object.fromEntries(
        Object.entries(formData).filter(([_, value]) => value !== "")
      ),
    };

    const updateUserProfile = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${APIEndtPoint}/vendors/${vendor_id}`, {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });

        const data = await response.json();
        if (response.ok) {
          setShowSnackbar(true);
          setTimeout(() => {
            navigate("/dashboard/profile");
          }, 1000);
        } else {
          const errorMessages = Array.isArray(data.error)
            ? data.error
            : [data.error];
          setErrors(errorMessages);
          throw new Error(`HTTP error: Status ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching services:", error);
      } finally {
        setLoading(false);
      }
    };

    updateUserProfile();
  };

  return (
    <div style={{ height: "100%" }}>
      <Sidebar data="profile" />
      <div className="main-container m-2">
        <div className="col content-container" style={{ marginTop: "20px" }}>
          <div className="d-flex position-relative justify-content-center align-items-center mb-4">
            <h4 className="fw-bold text-center w-100">Update Your Profile</h4>
          </div>
          {loading ? (
            <Loader />
          ) : (
            <form onSubmit={handleSubmit} className="mb-1 position-relative">
              <div className="border p-2 rounded bg-light">
                <div className="row">
                  <div className="col-md-6">
                    <h6 className="mt-1">Name</h6>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      placeholder="Enter Your Name"
                      value={formData.name}
                      onChange={handleInputChange}
                    />
                  </div>
                  {/* <div className="col-md-6">
                      <h6 className="mt-1">Email</h6>
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        placeholder="Enter Your Email"
                        value={formData.email}
                        onChange={handleInputChange}
                      />
                    </div> */}
                  <div className="col-md-6">
                    <h6 className="mt-1">Phone Number</h6>
                    <input
                      type="tel"
                      className="form-control"
                      name="phone_number"
                      placeholder="Enter Your Mobile Number"
                      value={formData.phone_number}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="col-md-6">
                    <h6 className="mt-1">Opening Time</h6>
                    <input
                      type="time"
                      className="form-control"
                      name="opening_time"
                      value={formData.opening_time}
                      onChange={handleInputChange}
                      onFocus={(e) => e.target.showPicker && e.target.showPicker()}
                    />
                  </div>

                  <div className="col-md-6">
                    <h6 className="mt-1">Closing Time</h6>
                    <input
                      type="time"
                      className="form-control"
                      name="closing_time"
                      value={formData.closing_time}
                      onChange={handleInputChange}
                      onFocus={(e) => e.target.showPicker && e.target.showPicker()}
                    />
                  </div>
                  <div className="col-md-12">
                    <h6 className="mt-1">Address</h6>
                    <textarea
                      className="form-control"
                      name="address"
                      placeholder="Enter Your Address"
                      value={formData.address}
                      onChange={handleInputChange}
                    />
                  </div>
                  {errors.length > 0 && (
                    <div className="mt-3">
                      <div style={{ color: "red", fontSize: "0.9em" }}>
                        {errors.map((error, index) => (
                          <div key={index}>{error}</div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="d-flex justify-content-center mt-3">
                <button
                  type="submit"
                  className="btn btn-primary responsive-btn me-2"
                >
                  Cancel
                  <i className="bi bi-cancel ms-2"></i>
                </button>
                <button
                  type="submit"
                  className="btn btn-primary responsive-btn me-2"
                >
                  Save
                  <i className="bi bi-save ms-2"></i>
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
      {showSnackbar && <PositionedSnackbar message={"Profile Updated!"} />}
    </div>
  );
}

export default UpdateProfile;
