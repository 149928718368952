import React, { useState } from "react";
import { APIEndtPoint } from "../API/Apis";
import Loader from "../Components/Loader";

function UpdateService({
  selectedService,
  setIsEditService,
  // setLoading,
  setSnackbarMessage,
  setShowSnackbar,
}) {
  const vendor_id = localStorage.getItem("token");
  const [serviceData, setServiceData] = useState(selectedService);
  const [errors, setErrors] = useState("");
  const [loading, setLoading] = useState(false);

  const handleTypeChange = (typeIndex, newName) => {
    const updatedServiceData = { ...serviceData };
    updatedServiceData.service_types[typeIndex].name = newName;
    setServiceData(updatedServiceData);
    setErrors("");
  };

  const handleDetailChange = (typeIndex, detailIndex, field, value) => {
    const updatedServiceData = { ...serviceData };
    updatedServiceData.service_types[typeIndex].service_details[detailIndex][
      field
    ] = value;
    setServiceData(updatedServiceData);
    setErrors("");
  };

  const handleAddServiceDetail = (typeIndex) => {
    const updatedServiceData = { ...serviceData };
    updatedServiceData.service_types[typeIndex].service_details.push({
      name: "",
      price: "",
    });
    setServiceData(updatedServiceData);
  };

  const handleRemoveServiceDetail = (typeIndex, detailIndex) => {
    const updatedServiceData = { ...serviceData };
    updatedServiceData.service_types[typeIndex].service_details.splice(detailIndex, 1);
    setServiceData(updatedServiceData);
  };  

  const handleSubmit = async () => {
    setLoading(true);
    setErrors("");

    try {
      const response = await fetch(`${APIEndtPoint}/vendors/${vendor_id}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ services: [serviceData] }),
      });
      if (response.ok) {
        setIsEditService(false);
        setSnackbarMessage("Service Updated!");
        setShowSnackbar(true);
      } else {
        const result = await response.json();
        if (result.error) {
          setErrors(result.error);
        } else if (result.errors) {
          setErrors(result.errors.join(", "));
        } else {
          setErrors("An unknown error occurred.");
        }
      }
    } catch (error) {
      console.error("Error updating service:", error);
      setErrors({ general: "An unexpected error occurred. Please try again." });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <h5 className="fw-bold">Service Types</h5>
          <form className="mt-3 form-responsive">
            {serviceData.service_types.map((type, typeIndex) => (
              <div
                key={typeIndex}
                className="mb-4 border p-2 rounded"
              >
                <div className="row position-relative">
                  <h6 className="mt-3">Service Type</h6>
                </div>
                <input
                  type="text"
                  className="form-control mb-1"
                  value={type.name}
                  onChange={(e) => handleTypeChange(typeIndex, e.target.value)}
                  placeholder="Enter service type name"
                />

                {type.service_details.map((detail, detailIndex) => (
                  <div
                    key={detail.id || detailIndex}
                    className="mb-1 position-relative"
                  >
                    {!detail.id && (
                      <i
                        className="bi bi-x position-absolute"
                        style={{
                          top: "2%",
                          right: "3%",
                          color: "red",
                          fontSize: "22px",
                          cursor: "pointer",
                        }}
                        onClick={() => handleRemoveServiceDetail(typeIndex, detailIndex)}
                      ></i>
                    )}
                    <div className="border p-2 rounded bg-light">
                      <div className="row">
                        <div className="col-md-8">
                          <h6 className="mt-1">Service Details</h6>
                          <textarea
                            className="form-control custom-height"
                            placeholder="Enter Service Details"
                            value={detail.name}
                            onChange={(e) =>
                              handleDetailChange(
                                typeIndex,
                                detailIndex,
                                "name",
                                e.target.value
                              )
                            }
                          />
                        </div>
                        <div className="col-md-4">
                          <h6 className="mt-1">Service Price</h6>
                          <input
                            type="number"
                            className="form-control custom-height"
                            placeholder="Enter Service Price"
                            value={detail.price}
                            onChange={(e) =>
                              handleDetailChange(
                                typeIndex,
                                detailIndex,
                                "price",
                                e.target.value
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => handleAddServiceDetail(typeIndex)}
                >
                  Add Service Detail <i className="bi bi-plus-circle ms-2"></i>
                </button>
              </div>
            ))}
            {errors && <div className="text-danger">{errors}</div>}
            <div className="m-4 d-flex justify-content-between">
              <button
                type="button"
                className="btn btn-primary responsive-btn"
                onClick={handleSubmit}
              >
                Submit
              </button>
              <button
                type="button"
                className="btn btn-primary responsive-btn"
                onClick={() => setIsEditService(false)}
              >
                Cancel
              </button>
            </div>
          </form>{" "}
        </>
      )}
    </>
  );
}

export default UpdateService;
